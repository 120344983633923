import React from "react";
import { Col, IconButton, Panel, FlexboxGrid } from "rsuite";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight } from "react-icons/bi";
import { isMobile } from "react-device-detect";

export default function ContentfulPageAnticoagulantOverview() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageAnticoagulant {
        nodes {
          title
          id
          url
          description {
            description
          }
        }
      }
    }
  `);
  return (
    <>
      {data.allContentfulPageAnticoagulant.nodes.map((node) => (
        <Col xs={24} md={6} style={{ marginBottom: isMobile ? "10px" : "0" }} key={node.title}>
          <Panel
            style={{
              backgroundColor: "white",
              height: "100%",
              cursor: "pointer",
            }}
            shaded
            onClick={() => navigate(`/patient/${node.url}`)}
          >
            <FlexboxGrid
              justify="space-between"
              style={{ flexFlow: "column wrap", height: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <h4
                  style={{
                    color: "#000",
                    fontSize: "22px",
                    marginBottom: "10px",
                    wordBreak: "break-word",
                    width: isMobile ? "100%" : "64%",
                  }}
                >
                  {node.title}
                </h4>
                <p
                  style={{
                    color: "#000",
                    lineHeight: "1.8",
                    fontSize: "16px",
                  }}
                >
                  {node.description.description}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <IconButton
                  appearance="primary"
                  icon={<Icon as={BiArrowToRight} size="1.5em" />}
                  size="md"
                  onClick={() => navigate(`/patient/${node.url}`)}
                  style={{ marginTop: "20px", backgroundColor: "#000" }}
                >
                  Verder lezen
                </IconButton>
              </div>
            </FlexboxGrid>
          </Panel>
        </Col>
      ))}
    </>
  );
}
