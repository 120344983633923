import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Navbar, Nav, Affix } from "rsuite";
import SearchPatientQuestions from "./searchPatientQuestions";
import { isMobile } from "react-device-detect";

export default function NavBarMain() {
  const [isFixed, setIsFixed] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      localSearchPatientQuestions {
        index
        store
      }
    }
  `);

  return (
    <>
      {domLoaded && (
        <Affix onChange={() => setIsFixed((prevIsFixed) => !prevIsFixed)}>
          <Navbar
            appearance="primary"
            style={{
              zIndex: "100",
              width: "100%",
              background: "#F6F4F5",
            }}
          >
            <Navbar.Brand>
              <Link to={`/patient`}>
                Start /{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    marginRight: "0",
                  }}
                >
                  Patiënt
                </span>
              </Link>
            </Navbar.Brand>
            <Nav>
              <div
                style={{
                  width: isMobile ? "80vw" : "40vw",
                  marginLeft: isMobile ? "-10px" : `calc(30vw - 128px)`,
                  position: "absolute",
                  paddingTop: "10px",
                }}
              >
                <SearchPatientQuestions
                  index={data.localSearchPatientQuestions.index}
                  store={data.localSearchPatientQuestions.store}
                />
              </div>
            </Nav>
          </Navbar>
        </Affix>
      )}
    </>
  );
}
