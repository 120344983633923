import * as React from "react";
import { graphql, Link } from "gatsby";
import LayoutPatient from "components/layout/patient";
import { Row, Col, Grid, List, IconButton, FlexboxGrid } from "rsuite";
import SectionWrapper from "../../components/section/sectionWrapper";
import ContentfulPageAnticoagulantOverview from "../../components/section/contentfulPageAnticoagulantOverview";
import ContentfulPageQuestionOverview from "../../components/section/contentfulPageQuestionOverview";
import ContentfulRecentOverview from "../../components/section/contentfulRecentOverview";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight, BiLinkExternal } from "react-icons/bi";
import { StaticImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";

const IndexPage = ({ data }) => {
  return (
    <LayoutPatient showNavBarSearch>
      <div
        style={{ position: "relative", padding: "0 20px" }}
        className="header-gradient"
      >
        <StaticImage
          src="../../images/internal/background-patient.png"
          alt="Logo Trombosestichting Nederland"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "-2",
          }}
          className="header-image"
        />
        <Grid>
          <Row
            style={{
              padding: isMobile ? "40px 20px 20px 20px" : "80px 0px 40px 0",
            }}
          >
            <Col xs={24} md={14} lg={12}>
              <h1
                style={{
                  color: "#fff",
                  lineHeight: "1.5",
                  fontSize: isMobile ? "2.2em" : "2.8em",
                  marginBottom: "15px",
                }}
              >
                Patiënteninformatie
              </h1>
              <p
                style={{
                  lineHeight: "1.5",
                  fontSize: "18px",
                  color: "white",
                  marginBottom: "20px",
                  fontWeight: "bold"
                }}
              >
                Antistollingsmiddelen zijn medicijnen die{" "}
                <Link
                  to="/patient/wat-is-trombose"
                  style={{ color: "#fff", textDecoration: "underline" }}
                >
                  trombose
                </Link>{" "}
                voorkomen en behandelen. Ze worden ook wel bloedverdunners
                genoemd. Ze verkleinen de kans op bloedstolsels, maar vergroten
                de kans op een bloeding. Er bestaan verschillende soorten
                antistollingsmiddelen. Hieronder vindt u meer informatie en
                antwoorden op veelgestelde vragen.
              </p>
            </Col>
          </Row>
        </Grid>
        <FlexboxGrid
          style={{
            alignItems: "stretch",
            width: "1120px",
            maxWidth: "100%",
            margin: "0 auto",
            paddingBottom: isMobile ? "40px" : "80px",
          }}
        >
          <ContentfulPageAnticoagulantOverview />
        </FlexboxGrid>
      </div>
      <SectionWrapper padded>
        <Grid>
          <Row style={{ marginBottom: "20px", color: "#000" }}>
            <Col>
              <h3>Veelgestelde vragen</h3>
            </Col>
          </Row>
          <Row>
            <ContentfulPageQuestionOverview />
          </Row>
        </Grid>
      </SectionWrapper>
      <div style={{ backgroundColor: "rgb(252, 234, 233)" }}>
        <SectionWrapper padded>
          <Grid>
            <Row style={{ marginBottom: "0px" }}>
              <Col>
                <h3 style={{ color: "#000" }}>Wat is trombose?</h3>
                <p
                  style={{
                    lineHeight: "2",
                    fontSize: "18px",
                    width: "95%",
                    paddingBottom: "20px",
                    color: "#000",
                  }}
                >
                  {data.contentfulPageWhatIs.description}
                </p>
                <p>
                  <Link to="/patient/wat-is-trombose">
                    <IconButton
                      appearance="primary"
                      icon={<Icon as={BiArrowToRight} size="1.5em" />}
                      size="lg"
                    >
                      Bekijken
                    </IconButton>
                  </Link>
                </p>
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
      <ContentfulRecentOverview />
      <SectionWrapper padded>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3 style={{ color: "#000" }}>Handige links & downloads</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <List bordered>
                <a
                  href="https://www.trombosestichting.nl/bestellen/antistollingspas/?utm_source=kennisplatform-antistolling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Item style={{ fontSize: "16px" }}>
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    De gratis Antistollingspas
                  </List.Item>
                </a>
                <a
                  href="https://www.trombosestichting.nl/over-ons/magazines/?utm_source=kennisplatform-antistolling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Item style={{ fontSize: "16px" }}>
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    Bestel de Magazines
                  </List.Item>
                </a>
                <a
                  href="https://www.trombosestichting.nl/bestellen//?utm_source=kennisplatform-antistolling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <List.Item style={{ fontSize: "16px" }}>
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    Brochures
                  </List.Item>
                </a>
              </List>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutPatient>
  );
};

export const data = graphql`
  query pageQuery {
    contentfulPageWhatIs(id: { eq: "84cb9dea-b04c-5691-86d5-da914af6d441" }) {
      url
      title
      id
      description
    }
  }
`;

export default IndexPage;
