import React from "react";
import { Container, Content, CustomProvider, Header } from "rsuite";
import NavBarMainPatient from "../ui/navBarMainPatient";
import NavBarTopPatient from "../ui/navBarTopPatient";
import { Helmet } from "react-helmet";
import "../../styles/styles.css";
import FooterPatient from "../section/footerPatient";
export default function LayoutPatient({ children, showNavBarSearch }) {
  return (
    <CustomProvider theme="light" locale="nl">
      <Helmet>
        <html lang="nl" />
        <title>Nederlands Kennisplatform Antistollingszorg | Patiënt</title>
        <meta
          name="description"
          content="Het doel van het Nederlands Kennisplatform Antistolling is om kennis, instrumenten en initiatieven op het gebied van antistollingszorg samen te brengen en toegankelijk te maken. Ben jij een patiënt of een professional met een vraag; het
          Kennisplatform leidt je naar een antwoord."
        />
      </Helmet>
      <Container>
        <Header>
          <NavBarTopPatient />
          <NavBarMainPatient showNavBarSearch={showNavBarSearch} />
        </Header>
        <Content>{children}</Content>
        <FooterPatient />
      </Container>
    </CustomProvider>
  );
}
