import React from "react";
import {
  Grid,
  Row,
  Col,
  IconButton,
  Panel,
  Tag,
  FlexboxGrid,
} from "rsuite";
import { useStaticQuery, graphql, navigate } from "gatsby";
import SectionWrapper from "./sectionWrapper";
import { isMobile } from "react-device-detect";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight } from "react-icons/bi";

export default function ContentfulRecentOverview() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageQuestion(
        limit: 3
        sort: { order: DESC, fields: pagequestion___updatedAt }
      ) {
        nodes {
          title
          url
          category
          updatedAt
        }
      }
    }
  `);
  return (
    <>
      <SectionWrapper padded background>
        <Grid>
          <Row>
            <Col xs={24}>
              <h3 style={{ margin: "0 0 20px 0", color: "#000" }}>
                Recent toegevoegde vragen
              </h3>
            </Col>
          </Row>
          <FlexboxGrid
            style={{
              alignItems: "stretch",
              width: "1120px",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            {data.allContentfulPageQuestion.nodes.map((node) => (
              <Col
                xs={24}
                md={8}
                style={{ marginBottom: isMobile ? "10px" : "0" }}
                key={node.title}
              >
                <Panel
                  style={{ backgroundColor: "white", height: "100%", cursor: "pointer" }}
                  bordered
                  onClick={() => navigate(`/patient/${node.url}`)}
                >
                  <FlexboxGrid
                    justify="space-between"
                    style={{ flexFlow: "column wrap", height: "100%" }}
                  >
                    <div style={{ width: "100%" }}>
                    <Tag size="sm" style={{ marginBottom: "10px" }}>{node.category}</Tag>
                      <h4
                        style={{
                          color: "#000",
                          fontSize: "22px",
                          marginBottom: "10px",
                          wordBreak: "break-word",
                        }}
                      >
                        {node.title}
                      </h4>
                    </div>
                    <div style={{ width: "100%" }}>
                      <IconButton
                        icon={<Icon as={BiArrowToRight} size="1.5em" />}
                        onClick={() => navigate(`/patient/${node.url}`)}
                        size="sm"
                        appearance="primary"
                        style={{ marginTop: "10px" }}
                      >
                        Bekijken
                      </IconButton>
                    </div>
                  </FlexboxGrid>
                </Panel>
              </Col>
            ))}
          </FlexboxGrid>
        </Grid>
      </SectionWrapper>
    </>
  );
}
