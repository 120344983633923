import React from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
export default function SectionWrapper({ children, padded, style, background }) {
  return (
   <>
   <BrowserView>
   <div style={{ padding: padded ? '80px 20px' : 0, backgroundColor: background ? '#F6F4F5' : "transparent", width: "100%", position: "relative", style }}>
      {children}
    </div>
    </BrowserView>
    <MobileView>
    <div style={{ padding: padded ? '40px 20px' : 0, backgroundColor: background ? '#F6F4F5' : "transparent", width: "100%", position: "relative", style }}>
      {children}
    </div>
    </MobileView>
   </>
  );
}
