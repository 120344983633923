import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Grid, Row, Col, Footer, List, Tag } from "rsuite";
import { StaticImage } from "gatsby-plugin-image";

export default function FooterPatient() {
  const data = useStaticQuery(graphql`
    query {
      siteBuildMetadata {
        buildTime(formatString: "DD MMMM YYYY HH:mm", locale: "nl")
      }
    }
  `);
  return (
    <>
      <Footer
        style={{
          backgroundColor: "#D72D22",
          color: "white",
          padding: "60px 20px",
        }}
      >
        <Grid>
          <Row>
            <Col sm={24} md={24} lg={12}>
              <StaticImage
                src="../../images/internal/logo-white.png"
                alt="Logo"
                style={{ width: "200px", marginTop: "5px" }}
              />
              <p style={{ margin: "20px 0", fontStyle: "italics" }}>
                Laatste update: {data.siteBuildMetadata.buildTime}
              </p>
              <Tag>v1.0.1</Tag>
              <p style={{ fontWeight: "bold", margin: "20px 0" }}>
              Mede mogelijk gemaakt door:<br />Vaillantfonds Landelijke Vereniging
              voor Crematoria
              </p>
            </Col>
            <Col sm={24} md={8} lg={6}>

            </Col>
            <Col sm={24} md={8} lg={6}>
              <List style={{ backgroundColor: "#D72D22" }}>
                <List.Item style={{ backgroundColor: "#D72D22" }}>
                  <Link
                    style={{ color: "#fff" }}
                    to="/patient/over-het-kennisplatform"
                  >
                    Over het Kennisplatform
                  </Link>
                </List.Item>
                <List.Item style={{ backgroundColor: "#D72D22" }}>
                  <Link style={{ color: "#fff" }} to="/patient/contact">
                    Contact
                  </Link>
                </List.Item>
                <List.Item style={{ backgroundColor: "#D72D22" }}>
                  <Link style={{ color: "#fff" }} to="/disclaimer">
                    Disclaimer
                  </Link>
                </List.Item>
                <List.Item style={{ backgroundColor: "#D72D22" }}>
                  <a href="https://www.iubenda.com/privacy-policy/92380080" target="_blank" rel="noreferrer noopener" style={{ color: "white" }}>
                    Privacybeleid
                  </a>
                </List.Item>
                <List.Item style={{ backgroundColor: "#D72D22" }}>
                  <a href="https://www.iubenda.com/privacy-policy/92380080/cookie-policy" target="_blank" rel="noreferrer noopener" style={{ color: "white" }}>
                    Cookiebeleid
                  </a>
                </List.Item>
              </List>
            </Col>
          </Row>
        </Grid>
      </Footer>
    </>
  );
}
