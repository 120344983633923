import React, { useState } from "react";
import { navigate } from "gatsby";
import { useFlexSearch } from "react-use-flexsearch";
import { Button, Col, IconButton, Panel, Tag } from "rsuite";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight, BiXCircle } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import { isMobile } from "react-device-detect";

const SearchPatientQuestions = ({ index, store }) => {
  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);

  return (
    <>
      <div style={{ padding: "0 5px" }}>
        {/* <h3 style={{ color: "#fff", marginBottom: "0px" }}>Zoeken in de vragen</h3> */}
        <Formik
          initialValues={{ query: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setQuery(values.query);
            setSubmitting(false);
          }}
        >
          <Form style={{ zIndex: "1500", position: "relative" }}>
            <>
              <Col sm={20} style={{ marginBottom: "10px", padding: "0" }}>
                <Field
                  name="query"
                  className="rs-input"
                  placeholder="Type uw zoekterm.."
                />
              </Col>
              <Col sm={4} style={{ marginBottom: "10px", padding: "0" }}>
                <Button
                  type="submit"
                  appearance="primary"
                  block
                >
                  Zoeken
                </Button>
              </Col>
            </>
          </Form>
        </Formik>
      </div>
      {results.length > 0 && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: isMobile ? "20px" : "20px 40px 40px 40px",
              backgroundColor: "#fff",
              maxHeight: "65vh",
              overflow: "scroll",
              width: isMobile ? "90vw" : "75vw",
              zIndex: "1001",
              margin: "0 auto",
              top: isMobile ? "24vh" : "15vh",
              boxShadow:
                "0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06)",
              borderRadius: "5px",
            }}
          >
            <BiXCircle
              style={{
                color: "#D72D22",
                fontSize: "30px",
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
              onClick={() => setQuery(null)}
            />
            <h3 style={{ color: "#000" }}>Zoekresultaten voor '{query}'</h3>
            {results.map((result) => (
                <Panel
                  key={result.id}
                  bordered
                  style={{
                    backgroundColor: "white",
                    marginTop: "20px",
                    cursor: "pointer",
                    position: "relative",
                    whiteSpace: "none"
                  }}
                  onClick={() => navigate(`/patient/${result.url}`)}
                >
                 <div>
                 <Tag size="sm" style={{ marginBottom: "10px" }}>
                    {result.category ? result.category : "Antistollingsmiddel"}
                  </Tag>
                  <h4
                    style={{
                      color: "#000",
                      fontSize: "22px",
                      marginBottom: "10px",
                      wordBreak: "break-word",
                      maxWidth: "95%",
                      position: "relative"
                    }}
                  >
                    {result.title}
                  </h4>
                  <IconButton
                    icon={<Icon as={BiArrowToRight} size="1.5em" />}
                    size="sm"
                    appearance="primary"
                  >
                    Resultaat bekijken
                  </IconButton>
                  </div>
                </Panel>
            ))}
          </div>
          <div
            style={{
              position: "fixed",
              zIndex: "1",
              width: "100vw",
              height: isMobile ? "100vh" : "100vh",
              padding: "20px 40px",
              top: "0",
              left: "0px",
              background:
                "radial-gradient(circle, rgba(255,255,255,0.8) 10%, rgba(214,232,238,0) 100%)",
            }}
            onClick={() => setQuery(null)}
            onKeyDown={() => setQuery(null)}
            aria-hidden="true"
          />
        </div>
      )}
      {results.length === 0 && query !== null && (
        <p style={{ fontWeight: "bold", paddingTop: "7px" }}>
          <span style={{ paddingLeft: "20px" }}>Geen resultaten gevonden!</span>
        </p>
      )}
    </>
  );
};

export default SearchPatientQuestions;
